import { render, staticRenderFns } from "./ClientProfile.vue?vue&type=template&id=2ddc78a1&scoped=true"
import script from "./ClientProfile.vue?vue&type=script&lang=js"
export * from "./ClientProfile.vue?vue&type=script&lang=js"
import style0 from "./ClientProfile.vue?vue&type=style&index=0&id=2ddc78a1&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ddc78a1",
  null
  
)

export default component.exports