<template>
  <div v-if="checkModule" class="section-pin">
    <form @submit.prevent.stop="onCheck">
      <label>PIN Check</label>
      <div class="input-area">
        <input v-model="pin" type="text" placeholder="Search PIN" />
        <span
          class="material-icons reset-pin"
          @click="reset()"
          v-if="this.pin.length"
          >close</span
        >
        &nbsp;
        <button class="d-flex"><i class="material-icons">search</i></button>
      </div>
      <small class="text-danger" v-if="pin.match(/\D/)"
        >Only numbers allowed!</small
      >
    </form>
    <ul class="pin-client-result">
      <li v-if="Object.keys(client).length > 0" @click="onVerified(client)">
        {{ client.name }}
        <small
          ><i>({{ client.email }})</i></small
        >
      </li>
    </ul>
  </div>
</template>

<script>
import AppConfig from "@/common/config/app.config.json";
import { request, util } from "@/common/mixins/mix_helper";
import { loader } from "@/common/mixins/mix_loader";

export default {
  name: "PINCheck",
  mixins: [request, util, loader],
  props: {
    thread: {
      required: true,
    },
  },
  data() {
    return {
      pin: "",
      client: {},
    };
  },
  methods: {
    onCheck: function () {
      if (this.pin === "") {
        this.$swal("Error", "PIN belum dimasukan", "error");
        return;
      }
      if (this.pin.match(/\D/)) {
        this.$swal("Error", "PIN hanya boleh angka !", "error");
        return;
      }

      this.loading();
      this.API.post(this.URL.modules.pin.check, {
        pin: this.pin,
      })
        .then(({ data }) => {
          this.client = data.data;
        })
        .catch((error) => {
          let err = this.handleCatchAxios(null, error, true);
          this.$swal("Error", err, "error");
        })
        .finally(() => {
          this.loading(false);
        });
    },

    onVerified: function (user) {
      const params = {
        ...user,
        pin: this.pin,
        subid: this.thread.subid,
      };
      this.loading();
      this.API.post(this.URL.modules.pin.verify, params)
        .then(() => {
          this.$swal("", "Successfully Verified", "success");
        })
        .catch(({ response }) => {
          this.$swal("ERROR", response.data.message, "error");
        })
        .finally(() => {
          this.loading(false);
        });
    },

    reset: function () {
      this.client = {};
      this.pin = "";
    },
  },
  computed: {
    checkModule() {
      return AppConfig.modules.includes("pinCheck");
    },
  },
  watch: {
    thread: function () {
      this.reset();
    },
  },
};
</script>

<style scoped lang="scss">
.section-pin {
  padding: 0 1em 0 1em;
  form {
    padding: 0;
    label {
      font-weight: bold;
    }
  }
  .input-area {
    display: flex;
    position: relative;
    input {
      border-radius: 6px !important;
    }
    .reset-pin {
      color: lightgray;
      position: absolute;
      right: 2em;
      margin-top: 0.4em;
      display: block;
      cursor: pointer;
    }
    button {
      border-radius: 0.4em !important;
      padding: 8px;
    }
  }
  ul {
    margin-top: 0.5em;
    list-style-type: decimal;
    padding-left: 1em;
  }
}

ul.pin-client-result {
  li {
    position: relative;
    &:hover {
      color: var(--success);
      cursor: pointer;
    }
  }
}
</style>
