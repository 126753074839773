<script>
import { mapGetters } from "vuex";
import { loader } from "@/common/mixins/mix_loader";
import { request } from "@/common/mixins/mix_helper";

export default {
  name: "SubmitTicket",
  mixins: [loader, request],
  data() {
    return {
      form: {
        title: "",
        summary: "",
        department: "",
        note: "",
        email: "",
        email_cc: "",
        is_need_confirm: true,
        attachments: [],
      },
      editorOptions: {
        theme: "snow",
        modules: {
          toolbar: [
            [{ header: [1, 2, 3, false] }],
            ["bold", "italic", "underline"],
            [{ list: "ordered" }, { list: "bullet" }],
          ],
        },
      },
    };
  },
  methods: {
    getSummary() {
      this.form = {
        title: "",
        summary: "",
        department: "",
        note: "",
        email: "",
        email_cc: "",
        attachments: [],
        is_need_confirm: true,
      };

      try {
        if (!this.getDialogActive.subid) {
          throw { message: "Thread tidak ditemukan!" };
        }
        if (this.getDialogActive.verified) {
          this.form.email = this.getDialogActive.email_verified;
        }

        this.loading();
        this.API.post(this.URL.ticket.summary, {
          subid: this.getDialogActive.subid,
        })
          .then(({ data }) => {
            this.form.summary = data.data.summary;
            this.form.title = data.data.title;
            this.$bvModal.show("modal-ticket");
          })
          .catch(({ response }) => {
            this.$swal("error", response.data.message, "error");
          })
          .finally(() => {
            this.loading(false);
          });
      } catch (e) {
        this.$swal("error", e.message, "error");
      }
    },

    onSubmit() {
      try {
        if (
          !this.form.department ||
          !this.form.title ||
          !this.form.summary ||
          !this.form.email
        ) {
          throw { message: "Form belum lengkap!" };
        }
        if (!this.getDialogActive.subid) {
          throw { message: "Thread tidak ditemukan!" };
        }

        const attachment = new FormData();
        for (const key in this.form.attachments) {
          attachment.append("files[]", this.form.attachments[key]);
        }
        attachment.append("title", this.form.title);
        attachment.append("summary", this.form.summary);
        attachment.append("department", this.form.department);
        attachment.append("note", this.form.note);
        attachment.append("email", this.form.email);
        attachment.append("email_cc", this.form.email_cc);
        attachment.append("is_need_confirm", this.form.is_need_confirm ? 1 : 0);
        attachment.append("subid", this.getDialogActive.subid);

        this.loading();
        this.API.post(this.URL.ticket.create, attachment)
          .then(({ data }) => {
            this.$swal(
              "Success",
              `Tiket berhasil disubmit, berikut ID Tiket nya :<br> <b>${data.data.id}</b>`,
              "success"
            );
          })
          .catch(({ response }) => {
            this.$swal("error", response.data.message, "error");
          })
          .finally(() => {
            this.$bvModal.hide("modal-ticket");
            this.loading(false);
          });
      } catch (e) {
        this.$swal("error", e.message, "error");
      }
    },

    onUploadAttachment(e) {
      const newFile = Array.from(e.target.files);
      newFile.forEach((file) => {
        if (
          !this.form.attachments.some(
            (existingFile) => existingFile.name === file.name
          )
        ) {
          if (file.size <= 2 * 1024 * 1024) {
            // 2MB = 2 * 1024 * 1024 bytes
            this.form.attachments.push(file);
          } else {
            this.$swal("Error", `File ${file.name} melebihi 2MB!`, "error");
          }
        }
      });
    },

    onRemoveFile(index) {
      this.form.attachments.splice(index, 1);
    },
  },
  computed: {
    ...mapGetters(["getDialogActive"]),
  },
};
</script>

<template>
  <div
    class="cursor-pointer rw-text-3"
    v-b-tooltip.hover
    title="Submit Ticket"
    @click="getSummary"
  >
    <i class="material-symbols-outlined">outgoing_mail</i>

    <b-modal
      id="modal-ticket"
      :title="`Open Ticket : ${getDialogActive.name} (${getDialogActive.email})`"
      size="lg"
      hide-footer
      no-close-on-esc
      no-close-on-backdrop
    >
      <form @submit.stop.prevent="onSubmit" class="p-2">
        <div class="form-group mb-2 w-1/2">
          <label class="mb-1 font-weight-bold text-dark"
            >Email <span class="text-danger">*</span></label
          >
          <input
            class="form-control form-control-sm shadow-none"
            type="email"
            v-model="form.email"
            required
            placeholder="Email clientzone pelanggan"
          />
        </div>
        <div class="form-group mb-1">
          <input
            class="form-control form-control-sm shadow-none"
            type="text"
            v-model="form.email_cc"
            placeholder="Email CC (optional)"
          />
          <small class="text-muted small" style="font-size: 0.7rem"
            >* Pisahkan dengan koma contoh. john@rumahweb.id,
            doe@rumahweb.id</small
          >
        </div>
        <div class="form-group flex items-center gap-3">
          <div class="w-1/2">
            <label class="text-sm font-weight-bold text-dark mb-1"
              >Department <span class="text-danger">*</span></label
            >
            <select
              class="form-control form-control-sm shadow-none"
              v-model="form.department"
              required
            >
              <option value="">Choose Department</option>
              <option value="Billing">Billing</option>
              <option value="Sales">Sales</option>
              <option value="Technical Support">Technical Support</option>
            </select>
          </div>
          <div class="mt-2">
            <br />
            <input
              type="checkbox"
              v-model="form.is_need_confirm"
              :checked="form.is_need_confirm"
            />
            Perlu konfirmasi pelanggan
          </div>
        </div>
        <div class="form-group">
          <label class="font-weight-bold text-dark mb-1"
            >Title <span class="text-danger">*</span></label
          >
          <input
            class="form-control form-control-sm shadow-none"
            type="text"
            v-model="form.title"
            required
            placeholder="Judul tiket"
          />
        </div>
        <div class="form-group">
          <label class="font-weight-bold text-dark mb-1"
            >Summary Message <span class="text-danger">*</span></label
          >
          <quill-editor v-model="form.summary" :options="editorOptions" />
        </div>

        <div class="form-group">
          <label class="font-weight-bold text-dark mb-1">Additional Note</label>
          <div class="small badge badge-info relative cursor-pointer mb-2">
            <input
              type="file"
              class="absolute p-0 left-0 opacity-0 cursor-pointer"
              @change="onUploadAttachment"
              multiple
            />
            <span class="material-symbols-outlined text-sm cursor-pointer"
              >attach_file_add</span
            >
            Add Attachment
          </div>
          <textarea
            id=""
            class="form-control shadow-none form-control-sm"
            v-model="form.note"
            placeholder="Catatan tambahan"
          ></textarea>
          <div class="flex gap-3 flex-wrap mt-2 align-items-center">
            <div
              class="small text-muted"
              v-for="(file, index) in form.attachments"
              :key="index"
            >
              <span class="">{{ file.name }}</span>
              <span
                class="material-symbols-outlined text-sm text-danger cursor-pointer hover:bg-red-200 hover:rounded"
                @click="onRemoveFile(index)"
                >close</span
              >
            </div>
          </div>
        </div>

        <div class="flex justify-content-end">
          <button class="btn btn-primary rounded rw-bg-3 border-0">
            Submit
          </button>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<style scoped lang="scss">
@import "~quill/dist/quill.core.css";
@import "~quill/dist/quill.snow.css";

.form-group > input,
.form-group > select {
  height: calc(1.5em + 0.5rem + 2px) !important;
}
.form-group {
  input {
    border: 1px solid #ced4da !important;
  }
}
</style>
