<template>
  <div class="conversation-list">
    <!-- generate icon svg -->
    <rw-icon></rw-icon>
    <div
      class="conversation-list__header"
      :class="switchOn ? '' : 'bg-header-away'"
    >
      <span
        @click="btnToPanel"
        style="cursor: pointer"
        v-b-tooltip.hover
        title="Panel"
      >
        {{ agent.nama }}
      </span>
      <div class="d-flex-center">
        <span>{{ switchOn ? "Online" : "Away" }}</span>
        <a
          href="javascript:void(0);"
          @click="toggleSwitch()"
          v-b-tooltip.hover
          :title="switchOn ? 'Go Away' : 'Go Online'"
        >
          <span
            class="switch"
            :class="switchOn ? 'switch--on' : ''"
            style="margin-left: 0"
          ></span>
        </a>
      </div>
      <a
        href="javascript:void(0);"
        @click="logout()"
        v-b-tooltip.hover
        title="Sign Out"
      >
        <svg
          width="28"
          height="28"
          viewBox="0 0 28 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="14" cy="14" r="11" stroke="#FDFDFD" stroke-width="2" />
          <path
            d="M17.4286 9C18.983 10.0917 20 11.9056 20 13.9586C20 17.2952 17.3137 20 14 20C10.6863 20 8 17.2952 8 13.9586C8 11.9056 9.01701 10.0917 10.5714 9"
            stroke="#FDFDFD"
            stroke-width="2"
            stroke-linecap="round"
          />
          <path
            d="M14 7.5V13"
            stroke="#FDFDFD"
            stroke-width="2"
            stroke-linecap="round"
          />
        </svg>
      </a>
    </div>

    <!-- header list tabs -->
    <div class="conversation-list__header search">
      <div class="d-flex">
        <!-- all -->
        <a
          href="javascript:void(0);"
          @click="filterThread('all')"
          :class="currentTab === 'all' ? 'active' : ''"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="#00aae5"
            xmlns="http://www.w3.org/2000/svg"
            class="svg-fill"
          >
            <path
              d="M12 2C10.6868 2 9.38642 2.25866 8.17317 2.7612C6.95991 3.26375 5.85752 4.00035 4.92893 4.92893C3.05357 6.8043 2 9.34784 2 12C2 14.6522 3.05357 17.1957 4.92893 19.0711C5.85752 19.9997 6.95991 20.7362 8.17317 21.2388C9.38642 21.7413 10.6868 22 12 22C14.6522 22 17.1957 20.9464 19.0711 19.0711C20.9464 17.1957 22 14.6522 22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7362 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2ZM7.07 18.28C7.5 17.38 10.12 16.5 12 16.5C13.88 16.5 16.5 17.38 16.93 18.28C15.57 19.36 13.86 20 12 20C10.14 20 8.43 19.36 7.07 18.28ZM18.36 16.83C16.93 15.09 13.46 14.5 12 14.5C10.54 14.5 7.07 15.09 5.64 16.83C4.62 15.5 4 13.82 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 13.82 19.38 15.5 18.36 16.83ZM12 6C10.06 6 8.5 7.56 8.5 9.5C8.5 11.44 10.06 13 12 13C13.94 13 15.5 11.44 15.5 9.5C15.5 7.56 13.94 6 12 6ZM12 11C11.6022 11 11.2206 10.842 10.9393 10.5607C10.658 10.2794 10.5 9.89782 10.5 9.5C10.5 9.10218 10.658 8.72064 10.9393 8.43934C11.2206 8.15804 11.6022 8 12 8C12.3978 8 12.7794 8.15804 13.0607 8.43934C13.342 8.72064 13.5 9.10218 13.5 9.5C13.5 9.89782 13.342 10.2794 13.0607 10.5607C12.7794 10.842 12.3978 11 12 11Z"
              fill="inherit"
            ></path>
          </svg>

          <div>All Conversation</div>
        </a>

        <!-- staff pending -->
        <a
          href="javascript:void(0);"
          @click="filterThread('staffPending')"
          :class="currentTab === 'staffPending' ? 'active' : ''"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="#00aae5"
            xmlns="http://www.w3.org/2000/svg"
            class="svg-fill"
          >
            <path
              d="M18 21L15 18L18 15V17H22V19H18V21ZM13 18C13 18.71 13.15 19.39 13.42 20H2V17C2 14.79 5.58 13 10 13C11 13 11.96 13.09 12.85 13.26C13.68 13.42 14.44 13.64 15.11 13.92C13.83 14.83 13 16.32 13 18ZM4 17V18H11C11 16.96 11.23 15.97 11.64 15.08L10 15C6.69 15 4 15.9 4 17ZM10 4C11.0609 4 12.0783 4.42143 12.8284 5.17157C13.5786 5.92172 14 6.93913 14 8C14 9.06087 13.5786 10.0783 12.8284 10.8284C12.0783 11.5786 11.0609 12 10 12C8.93913 12 7.92172 11.5786 7.17157 10.8284C6.42143 10.0783 6 9.06087 6 8C6 6.93913 6.42143 5.92172 7.17157 5.17157C7.92172 4.42143 8.93913 4 10 4ZM10 6C9.46957 6 8.96086 6.21071 8.58579 6.58579C8.21071 6.96086 8 7.46957 8 8C8 8.53043 8.21071 9.03914 8.58579 9.41421C8.96086 9.78929 9.46957 10 10 10C10.5304 10 11.0391 9.78929 11.4142 9.41421C11.7893 9.03914 12 8.53043 12 8C12 7.46957 11.7893 6.96086 11.4142 6.58579C11.0391 6.21071 10.5304 6 10 6Z"
              fill="inherit"
            ></path>
          </svg>
          Agent Pending
        </a>

        <!-- client pending -->
        <a
          href="javascript:void(0);"
          @click="filterThread('clientPending')"
          :class="currentTab === 'clientPending' ? 'active' : ''"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="#00aae5"
            xmlns="http://www.w3.org/2000/svg"
            class="svg-fill"
          >
            <path
              d="M21.1 12.5L22.5 13.91L15.97 20.5L12.5 17L13.9 15.59L15.97 17.67L21.1 12.5ZM11 4C12.0609 4 13.0783 4.42143 13.8284 5.17157C14.5786 5.92172 15 6.93913 15 8C15 9.06087 14.5786 10.0783 13.8284 10.8284C13.0783 11.5786 12.0609 12 11 12C9.93913 12 8.92172 11.5786 8.17157 10.8284C7.42143 10.0783 7 9.06087 7 8C7 6.93913 7.42143 5.92172 8.17157 5.17157C8.92172 4.42143 9.93913 4 11 4ZM11 6C10.4696 6 9.96086 6.21071 9.58579 6.58579C9.21071 6.96086 9 7.46957 9 8C9 8.53043 9.21071 9.03914 9.58579 9.41421C9.96086 9.78929 10.4696 10 11 10C11.5304 10 12.0391 9.78929 12.4142 9.41421C12.7893 9.03914 13 8.53043 13 8C13 7.46957 12.7893 6.96086 12.4142 6.58579C12.0391 6.21071 11.5304 6 11 6ZM11 13C11.68 13 12.5 13.09 13.41 13.26L11.74 14.93L11 14.9C8.03 14.9 4.9 16.36 4.9 17V18.1H11.1L13 20H3V17C3 14.34 8.33 13 11 13Z"
              fill="inherit"
            ></path>
          </svg>
          Client Pending
        </a>

        <!-- client away -->
        <a
          href="javascript:void(0);"
          @click="filterThread('clientAway')"
          :class="currentTab === 'clientAway' ? 'active' : ''"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="#00aae5"
            xmlns="http://www.w3.org/2000/svg"
            class="svg-fill"
          >
            <path
              d="M20 12V7H22V12H20ZM20 16V14H22V16H20ZM10 13C12.67 13 18 14.34 18 17V20H2V17C2 14.34 7.33 13 10 13ZM10 4C11.0609 4 12.0783 4.42143 12.8284 5.17157C13.5786 5.92172 14 6.93913 14 8C14 9.06087 13.5786 10.0783 12.8284 10.8284C12.0783 11.5786 11.0609 12 10 12C8.93913 12 7.92172 11.5786 7.17157 10.8284C6.42143 10.0783 6 9.06087 6 8C6 6.93913 6.42143 5.92172 7.17157 5.17157C7.92172 4.42143 8.93913 4 10 4ZM10 14.9C7.03 14.9 3.9 16.36 3.9 17V18.1H16.1V17C16.1 16.36 12.97 14.9 10 14.9ZM10 5.9C9.44305 5.9 8.9089 6.12125 8.51508 6.51508C8.12125 6.9089 7.9 7.44305 7.9 8C7.9 8.55695 8.12125 9.0911 8.51508 9.48492C8.9089 9.87875 9.44305 10.1 10 10.1C10.557 10.1 11.0911 9.87875 11.4849 9.48492C11.8788 9.0911 12.1 8.55695 12.1 8C12.1 7.44305 11.8788 6.9089 11.4849 6.51508C11.0911 6.12125 10.557 5.9 10 5.9Z"
              fill="inherit"
            ></path>
          </svg>
          Client Away
        </a>
      </div>
    </div>

    <!-- list people -->
    <div
      class="conversation-list-item mb-2"
      style="overflow: hidden auto; height: 78vh"
    >
      <div
        class="is-unstable-connection visible"
        v-if="!getAllVuexAgent._isConnect.firebase"
      >
        <!-- <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="1.85962" height="14.8769" rx="0.929808" transform="matrix(0.716994 0.69708 -0.716994 0.69708 10.667 0)" fill="#555555"></rect> <rect width="1.85962" height="14.8769" rx="0.929808" transform="matrix(0.716994 -0.69708 0.716994 0.69708 0 1.62939)" fill="#555555"></rect>
        </svg>-->
        <strong>Unstable connection detected.</strong>
        <p>
          You may have a new message that not rendered yet, click here to
          refresh your message list
        </p>
        <button @click="$router.go()">Refresh</button>
      </div>

      <div class="skeleton" v-if="showSkeleton">
        <b-skeleton-img no-aspect height="60px"></b-skeleton-img>
        <b-skeleton-img no-aspect height="60px"></b-skeleton-img>
        <b-skeleton-img no-aspect height="60px"></b-skeleton-img>
        <b-skeleton-img no-aspect height="60px"></b-skeleton-img>
      </div>
      <template v-else>
        <div v-if="threads.length === 0">
          <div
            class="is-unstable-connection visible"
            style="background: transparent; color: #00aae5"
          >
            <strong>Thread not found</strong>
          </div>
        </div>
        <div class="box-people mb-24" v-else-if="threads.length !== 0">
          <div
            class="people-item"
            @click="getMessage(people.id)"
            v-for="(people, key) in threads"
            :key="key"
            :title="people.name"
            :class="{
              active: people.id === activeDialog,
              unread: people.unread,
              verified: people.verified,
            }"
          >
            <div
              class="people-header"
              style="display: flex; justify-content: space-between"
            >
              <div class="people-name flex gap-1 items-center">
                <span
                  class="material-symbols-outlined text-xs"
                  v-if="people.verified"
                  >verified</span
                >
                <div class="text-truncate">{{ toHTML(people.name) }}</div>
              </div>
              <div class="people-time">
                <svg v-if="delayIsExpired(people.id)" class="faa-ring">
                  <use xlink:href="#rw-alert"></use>
                </svg>

                <svg v-else-if="people.unread" class="icon-incoming-message">
                  <use xlink:href="#rw-comment"></use>
                </svg>

                <span v-else
                  >{{ calculateTimeAgo(people.time, people.now) }}m</span
                >
              </div>
            </div>
            <div class="people-last-msg flex w-100 gap-1">
              <span
                v-if="people.reply"
                class="material-symbols-outlined text-sm"
                >reply_all</span
              >
              <span
                v-html="getFirstLineText(people.message)"
                class="text-truncate"
              />
            </div>
          </div>
        </div>
      </template>
    </div>

    <div class="net-status px-2 py-1 border-top position-absolute">
      <NetworkStatus />
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { SkeletonPlugin, TooltipPlugin } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
Vue.use(SkeletonPlugin);
Vue.use(TooltipPlugin);

import $ from "jquery";
import rwIcon from "@/components/widget/IconSvg";
import { loader } from "@/common/mixins/mix_loader";
import { lsConfig, snooze } from "@/common/mixins/mix_localStorage";
import { mapGetters } from "vuex";
import { staffCookies } from "@/common/mixins/mix_cookies";
import {
  request,
  rwSound,
  waktu,
  alert,
  util,
} from "@/common/mixins/mix_helper";
import AppConfig from "@/common/config/app.config.json";
import NetworkStatus from "@/components/widget/NetworkStatus";

let _getCookies = staffCookies.data().cookies.get();
let _activeDialog =
  typeof _getCookies.threadOpen !== "undefined" &&
  typeof _getCookies.threadOpen.id !== "undefined"
    ? _getCookies.threadOpen.id
    : null;

export default {
  mixins: [
    staffCookies,
    loader,
    request,
    waktu,
    lsConfig,
    rwSound,
    snooze,
    alert,
    util,
  ],
  components: {
    rwIcon,
    NetworkStatus,
  },
  data() {
    return {
      currentTab: "all",
      activeDialog: _activeDialog,
      filter: {
        status: false,
        record: [],
      },
      agent: {
        nama: "Agent Name",
      },
      showSkeleton: true,
    };
  },
  methods: {
    /**
     * --------------------------------------------------------------
     * toggle status on / away
     * --------------------------------------------------------------
     */
    toggleSwitch: function () {
      this.loading();
      this.$store
        .dispatch("staffChangeStatus", {
          status: this.switchOn ? "away" : "on",
        })
        .then(() => {
          this.cookies.add({ isOnline: !this.switchOn });
          this.$store.commit("toggleIsOnline", !this.switchOn);
          // this.switchOn = !this.switchOn;
          let status = this.switchOn ? "Available" : "Not Available";
          this.$swal({
            title: "Agent Status : " + status,
            html: "Your status is now changed to " + status,
            icon: "success",
          });
        })
        .catch((error) => {
          this.$swal("Error", error.data.message, "error");
        })
        .finally(() => {
          this.loading(false);
        });
    },

    /**
     * --------------------------------------------------------------
     * get detail percakapan
     * --------------------------------------------------------------
     */
    getMessage: function (id) {
      this.loading();
      /**
       * --------------------------------------------------------------
       * request list agent where thread has been clik by agent
       * --------------------------------------------------------------
       */
      let postThread = { threadid: id };
      this.API.post(this.URL.agent.index, postThread)
        .then(({ data }) => {
          this.activeDialog = id;

          if (this.threads.length !== 0) {
            /**
             * --------------------------------------------------------------
             * cari thread and store to vuex thread active
             * toggleUserShow : trigger vuex untuk menampilkan info customer
             * --------------------------------------------------------------
             */
            let thread = this.threads.find((people) => people.id === id);
            this.$store.dispatch("ThreadSelected", {
              agents: data.data,
              dialog: thread,
            });
            this.$store.dispatch("toggleUserShow", { show: true });

            /**
             * --------------------------------------------------------------
             * staffGetAllMessage : request list message & save in vuex
             * Draw : reset draw loadmore to 1, setLast : false
             * setUuidMessageMoreOpened : reset to null
             * --------------------------------------------------------------
             */
            this.$store.dispatch("staffGetAllMessage", postThread);
            this.$store.dispatch("Draw", { type: "reset" });
            this.$store.dispatch("setUuidMessageMoreOpened", null);
            this.$store.commit("isKnowledgeBase", false);
            this.$emit("fromChildDialogSelected", {
              type: "selectThread",
              threadid: id,
            });
          }
        })
        .catch((err) => {
          let message =
            typeof err.response !== "undefined"
              ? err.response.data.message
              : err.message;
          this.$swal("error", message, "error");
        })
        .finally(() => {
          this.loading(false);
          setTimeout(() => {
            this.scrollToBottomChat();
            $("#staff-textarea").focus();
          }, 400);
        });
    },

    /**
     * --------------------------------------------------------------
     * for filter dialog or thread
     * clientAway: more than 5 minute customer not reply && was reply by staff
     * clientPending: waiting response from client & sudah reply oleh agent
     * staffPending:  staff not yet reply
     * default: show all threads
     * --------------------------------------------------------------
     */
    filterThread: function (tipe = "all") {
      let threads = [];
      let th = this.getThreads.all;
      let timeAway = this.mixConfigGetter().time_away;
      this.currentTab = tipe;
      this.filter.status = true;

      let customerAway = parseInt(this.mixEpoch() - parseInt(timeAway) * 60);
      switch (tipe) {
        case "staffPending":
          threads = th.filter(
            (thread) =>
              thread.unread || !thread.reply || this.isSnoozed(thread.id)
          );
          break;

        case "clientPending":
          threads = th.filter(
            (thread) => thread.reply && parseInt(thread.time) >= customerAway
          );
          break;

        case "clientAway":
          threads = th.filter(
            (thread) => thread.reply && parseInt(thread.time) <= customerAway
          );
          break;

        default:
          this.filter.status = false;
          threads = th;
          break;
      }
      this.filter.record = threads;
    },

    /**
     * --------------------------------------------------------------
     * check apakah thread id params ada di snooze
     * --------------------------------------------------------------
     */
    isSnoozed(threadid) {
      return this.getSnoozeActive.record.find(
        (snooze) => snooze.threadId === threadid
      );
    },

    /**
     * --------------------------------------------------------------
     * proses logout, check api all thread was closed
     * --------------------------------------------------------------
     */
    logout: function () {
      this.$swal({
        title: "Are you sure?",
        html: "You have to close all chats first",
        icon: "warning",
        showCancelButton: true,
      }).then(({ isConfirmed }) => {
        if (isConfirmed) {
          this.loading();
          let roti = this.cookies.get();
          if (
            typeof roti !== "undefined" &&
            typeof roti.staff !== "undefined"
          ) {
            this.API.post(this.URL.agent.logout, {
              message: "Logout from panel agent",
            })
              .then(() => {
                this.$store.dispatch("FbAuthSignOut");
                this.cookies.remove();
                sessionStorage.clear();
                Object.keys(AppConfig.rwStorage.agent).forEach((key) => {
                  localStorage.removeItem(AppConfig.rwStorage.agent[key]);
                });
                location.href = "/auth";
              })
              .catch(({ response }) => {
                this.$swal("Error", response.data.message, "error");
              })
              .finally(() => {
                this.loading(false);
              });
          } else {
            this.loading(false);
            this.mixSwalBlock(
              "Cookies not found! please refresh this page.",
              "Error",
              "error"
            );
          }
        }
      });
    },

    scrollToBottomChat: function () {
      let el = $(".qcw-comments.isReading");
      if (el.length) {
        el.scrollTop(parseInt(el[0].scrollHeight));
      }
    },

    /**
     * --------------------------------------------------------------
     * for time ago thread
     * --------------------------------------------------------------
     */
    calculateTimeAgo: function (lastTime, sekarang) {
      let time = parseInt(sekarang) - parseInt(lastTime);
      let bulat = Math.round(time / 60);
      return bulat < 0 ? 0 : bulat;
    },

    /**
     * --------------------------------------------------------------
     * for get icon alert snooze sudah habis
     * --------------------------------------------------------------
     */
    delayIsExpired: function (threadid) {
      return this.getSnoozeActive.alert.find((id) => id === threadid);
    },

    /**
     * --------------------------------------------------------------
     * jika menggunakan router-link ada issue di header panelnya
     * --------------------------------------------------------------
     */
    btnToPanel: function () {
      window.open("/panel", "_blank");
    },

    /**
     * --------------------------------------------------------------
     * request current status base on mounted (reload) default away
     * --------------------------------------------------------------
     */
    getCurrentStatus: function () {
      this.API.get(this.URL.agent.status)
        .then(({ data }) => {
          if (data.status === "off") {
            /**
             * --------------------------------------------------------------
             * force logout if status off (maybe has been kicked by spv)
             * --------------------------------------------------------------
             */
            this.$store.dispatch("FbAuthSignOut");
            this.cookies.remove();
            Object.keys(AppConfig.rwStorage.agent).forEach((key) => {
              localStorage.removeItem(AppConfig.rwStorage.agent[key]);
            });
            location.href = "/auth";
          } else {
            let status = data.status === "on";
            this.cookies.add({ isOnline: status });
            this.$store.commit("toggleIsOnline", status);
          }
        })
        .catch(() => {
          this.cookies.add({ isOnline: false });
          this.$store.commit("toggleIsOnline");
        })
        .finally(() => {
          setTimeout(() => {
            this.showSkeleton = false;
          }, 500);
        });
    },
    visibilityChange() {
      this.$store.dispatch("DialogUpTime", { now: this.mixEpoch() });
    },
    getFirstLineText: function (text) {
      const string = text.replace(/<br\s*\/?>/gi, "\n");
      return string.split(/\s*\n\s*/).find((line) => line.trim() !== "");
    },
  },
  computed: {
    ...mapGetters(["getThreads", "getSnoozeActive", "getAllVuexAgent"]),
    threads: function () {
      if (this.filter.status) {
        let recordLength = Object.keys(this.filter.record).length;
        return recordLength !== 0 ? this.filter.record : [];
      } else {
        return this.getThreads.all;
      }
    },
    listenThreadMove() {
      return this.$store.state.staffChat._threads.move;
    },
    switchOn: function () {
      return this.getAllVuexAgent._isOnline;
    },
  },
  watch: {
    /**
     * --------------------------------------------------------------
     * listen cron tab, jadi setiap 1menit atau 00 script ini akan
     * melakukan re filtering thread tergantung dengan current tab
     * --------------------------------------------------------------
     */
    listenThreadMove: function (prosesMove) {
      if (prosesMove && this.currentTab !== "all") {
        this.filterThread(this.currentTab);
      }
      // hanya untuk reset value jadi false
      setTimeout(() => {
        this.$store.dispatch("DialogMove", { move: false });
      }, 2000);
    },
  },
  created() {
    document.addEventListener("visibilitychange", this.visibilityChange, false);
  },
  mounted() {
    /**
     * --------------------------------------------------------------
     * auto open thread
     * delay, butuh waktu untuk get thread from API asumsi 1s cukup
     * jika activeDialog tidak null & threadid ada di list thread
     * --------------------------------------------------------------
     */
    let current = this.activeDialog;
    if (current !== null) {
      setTimeout(() => {
        let isExist = this.getThreads.all.find(({ id }) => id === current);
        if (isExist !== "undefined") {
          this.getMessage(current);
        }
      }, 1000);
    }

    let token = localStorage.getItem(AppConfig.rwStorage.agent.token);
    if (token !== null) {
      let agent = this.jwtDecode(token);
      if (typeof agent.nama !== "undefined") {
        this.agent.nama = agent.nama;
      }
    }

    /**
     * --------------------------------------------------------------
     * init status
     * --------------------------------------------------------------
     */
    this.getCurrentStatus();

    /**
     * --------------------------------------------------------------
     * watch perubahan vuex, untuk trigger dialog ketika klik notif
     * --------------------------------------------------------------
     */
    this.$store.watch(
      (state) => {
        return state.staffChat._threads.getMessage.get;
      },
      (baru) => {
        if (baru) {
          let threadid = this.getAllVuexAgent._threads.getMessage.threadid;
          this.getMessage(threadid);
          this.$store.dispatch("getMessage", {
            get: false,
            threadid: null,
          });
        }
      }
    );
  },
};
</script>

<style scoped>
@import "../../assets/css/conversation.css";
@import "../../assets/css/ringing.css";
.bg-header-away {
  background: orange;
}
</style>

<style scoped lang="scss">
.net-status {
  background: #fafafa;
  bottom: 0;
  width: 100%;
}

//.people-item {
//  .verified {
//    border: 1px solid var(--success) !important;
//  }
//
//  .active {
//    .verified{
//      border: none !important;
//    }
//  }
//}
</style>
