import { staffCookies } from "@/common/mixins/mix_cookies";
import { waktu, util } from "@/common/mixins/mix_helper";
import { mapGetters } from "vuex";
import AppConfig from "@/common/config/app.config.json";

export const agentMessage = {
  methods: {
    mixGenerateMessage: function (dinamisField) {
      let Cookies = staffCookies.data().cookies.get();

      if (
        typeof Cookies !== "undefined" &&
        typeof Cookies.staff !== "undefined" &&
        typeof Cookies.isLogin !== "undefined" &&
        typeof Cookies.staff.id !== "undefined" &&
        typeof Cookies.threadOpen !== "undefined" &&
        typeof Cookies.threadOpen.id !== "undefined" &&
        // typeof Cookies.threadOpen.fbid !== "undefined" &&
        Cookies.isLogin
      ) {
        let uid = () => {
          let str = Math.random().toString(36).substring(2);
          let epoch = Math.round(Date.now() / 1000);
          return str + "." + epoch;
        };
        let defaultField = {
          id: parseInt(Cookies.staff.id),
          time: waktu.methods.mixEpoch(),
          name: Cookies.staff.name,
          tipe: "text",
          uuid: uid(),
          actor: "staff",
          status: "pending",
          staffid: parseInt(Cookies.staff.id), // for manipulate append
          message: "message default mixin",
          threadid: Cookies.threadOpen.id,
          is_reply: true,
        };
        return Object.assign(defaultField, dinamisField);
      }
      return false;
    },
  },
};

export const clientMessage = {
  mixins: [util, waktu],
  methods: {
    /**
     * --------------------------------------------------
     * handle send message client
     * --------------------------------------------------
     */
    clientSendMessage: async function (message, opt = {}) {
      if (typeof this.getConfigJs.threadid === "undefined") {
        throw "missing threadid";
      }

      let params = {
        time: this.mixEpoch(),
        tipe: "text",
        actor: "customer",
        message: this.escape(message),
        uuid: this.UUID(),
        threadid: this.getConfigJs.threadid,
        status: "pending",
      };

      params = { ...params, ...opt };

      await this.$store.dispatch("MessagePush", params);
      this.$store
        .dispatch("customerSendMessage", params)
        .then(({ data }) => {
          if (typeof data.data.token !== "undefined") {
            localStorage.setItem(
              AppConfig.rwStorage.customer.token,
              data.data.token
            );
          }

          if (typeof params.status !== "undefined") {
            params["status"] = "sent";
          }
          params["time"] = data.data.time ? data.data.time : params["time"];

          const messageIndex = this.getAllMessage.findIndex(
            (e) => e.uuid === params.uuid
          );

          this.$store.dispatch("MessageUpdate", {
            index: messageIndex,
            record: params,
          });
        })
        .catch(() => {
          if (typeof params.status !== "undefined") {
            params["status"] = "failed";
          }
          const messageIndex = this.getAllMessage.findIndex(
            (e) => e.uuid === params.uuid
          );

          this.$store.dispatch("MessageUpdate", {
            index: messageIndex,
            record: params,
          });
        });
    },
  },
  computed: {
    ...mapGetters(["getConfigJs", "getAllMessage"]),
  },
};
