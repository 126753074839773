import { render, staticRenderFns } from "./BaseChat.vue?vue&type=template&id=035b1c99&scoped=true"
import script from "./BaseChat.vue?vue&type=script&lang=js"
export * from "./BaseChat.vue?vue&type=script&lang=js"
import style0 from "./BaseChat.vue?vue&type=style&index=0&id=035b1c99&prod&lang=css"
import style1 from "./BaseChat.vue?vue&type=style&index=1&id=035b1c99&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "035b1c99",
  null
  
)

export default component.exports