<template>
  <div
    v-if="dialogNotEmpty"
    class="conversation-info position-relative"
    :class="getUserInfoOpen ? 'show' : ''"
  >
    <!--header-->
    <div class="header">
      <span class="close-chat-info" @click="toggleChatInfo()">×</span>
      <span class="title"> Chat &amp; Customer Info </span>
    </div>

    <!--body content-->
    <div class="body">
      <ClientProfile
        :thread="getDialogActive"
        :duration="_handleTimer"
        class="py-2 px-3"
      />
      <PINCheck :thread="getDialogActive" v-if="!getDialogActive.verified" />
      <Tags :threadid="getDialogActive.id" />
      <div class="conversation-info__bottom">
        <ul>
          <!-- fot button -->
          <li>
            <div class="rw-action-box">
              <button @click="btnOpenAddAgent()">Add Agent</button>
              <button @click="xhrExport()">Export</button>
              <button @click="cannedMessageOpen = true">Canned</button>
              <button @click="end_chat(getDialogActive.id)" class="bg-red">
                End Chat
              </button>
              <button
                v-if="getListAgent.length > 1"
                @click="leave_chat(getDialogActive.id)"
              >
                Leave
              </button>
            </div>
          </li>

          <!-- for agents list -->
          <li>
            <span>Agents</span>
            <div class="conversation-bottom__participants">
              <div class="empty-state" v-if="getListAgent.length === 0">
                No Agents Assigned Yet
              </div>
              <ul v-else>
                <li
                  v-for="(agent, key) in getListAgent"
                  :key="key"
                  style="border-bottom: none; padding-bottom: 1px"
                >
                  <div
                    class="participants-avatar__img"
                    :style="{ backgroundImage: 'url(' + image.staff + ')' }"
                  ></div>
                  <div class="participants-detail__info">
                    <div class="flex">
                      {{ agent.nama }} - {{ agent.status.toUpperCase() }}
                    </div>
                    <span class="text-ellipsis">{{ agent.email }}</span>
                  </div>
                </li>
              </ul>
            </div>
          </li>

          <!-- canned message-->
          <li v-if="cannedMessageOpen">
            <div class="additional-info-window">
              <div class="additional-info__header">
                <img
                  src="img/left-arrow-grey.svg"
                  @click="cannedMessageOpen = false"
                  alt="Back"
                />
                Canned message
              </div>
              <div class="additional-info__body">
                <div style="width: 100%">
                  <form>
                    <input
                      type="text"
                      v-model="form.cannedSearch"
                      @keyup="cannedSearch()"
                      placeholder="Search canned message"
                    />
                  </form>
                  <ul
                    style="height: 77vh; overflow: hidden auto"
                    class="canned-ul"
                    v-if="arr.canned.length"
                  >
                    <li
                      v-for="(canned, key) in arr.canned"
                      :key="key"
                      class="canned-list"
                      @click="sendCanned(canned.id)"
                    >
                      <h4>{{ canned.title }}</h4>
                      <div class="text-truncate" :title="canned.message">
                        <small>{{ canned.message }}</small>
                      </div>
                    </li>
                  </ul>
                  <ul v-else style="width: 100%">
                    <li style="text-align: center">Canned tidak tersedia</li>
                  </ul>
                </div>
              </div>
            </div>
          </li>

          <!-- tambah agents -->
          <li v-if="addAgentOpen" class="addAgent">
            <div class="additional-info-window">
              <div class="additional-info__header">
                <img
                  src="img/left-arrow-grey.svg"
                  @click="addAgentOpen = false"
                  alt="left"
                />
                Add Agent
              </div>
              <div class="additional-info__body">
                <p class="agent-notif">
                  If you choose a <code>random agent</code>, the system will
                  send to the agent with <code>online</code> status.
                </p>

                <form
                  @submit.stop.prevent="onSubmitAddAgent()"
                  style="padding-top: 10px"
                >
                  <div class="form-group">
                    <label>Pilih Departemen</label>
                    <select
                      v-model="form.departemen"
                      @change="triggerChangeDepartement"
                    >
                      <option value="none">-- Select Department --</option>
                      <option
                        :value="dept.id"
                        v-for="(dept, key) in arr.departemen"
                        :key="key"
                      >
                        {{ dept.nama }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label>Pilih Agent</label>
                    <select v-model="form.staff">
                      <option :value="storage.randomAgentId">
                        Random Agent
                      </option>
                      <option
                        :value="staf.id"
                        v-for="(staf, key) in arr.staff"
                        :key="key"
                      >
                        {{ staf.nama }} - {{ staf.status }}
                      </option>
                    </select>
                  </div>
                  <button>Submit</button> &nbsp;
                  <button class="btn btn-cancel" @click="addAgentOpen = false">
                    Cancel
                  </button>
                </form>
              </div>
            </div>
          </li>

          <!-- list for result searching message -->
          <li v-if="getSearch.formOpen">
            <div class="additional-info-window">
              <div class="additional-info__header">
                <img
                  src="img/left-arrow-grey.svg"
                  @click="
                    $store.dispatch('SearchMessage', {
                      formOpen: false,
                      keyword: '',
                      result: [],
                    })
                  "
                  alt="Back"
                />
                Search Messages
              </div>
              <div
                v-if="getSearch.result.length"
                class="additional-info__body"
                style="height: 85vh; overflow: hidden auto"
              >
                <ul class="search-message">
                  <li
                    v-for="(message, key) in getSearch.result"
                    :key="key"
                    @click="selectResultSearchMessage(message)"
                  >
                    <small
                      >{{ mixEpochToHours(message.time) }} -
                      {{ message.name }}</small
                    >
                    <br />
                    <small v-if="message.tipe === 'image'"
                      >[IMAGE] &nbsp;</small
                    >
                    <p v-html="message.message" class="mb-0" />
                  </li>
                </ul>
              </div>
              <div
                class="additional-info__body"
                style="align-items: center; justify-content: center"
                v-else
              >
                <ul>
                  <li style="text-align: center">
                    Searching For message <br />
                    {{ getDialogActive.name }}
                  </li>
                </ul>
              </div>
            </div>
          </li>

          <!-- for snooze list -->
          <li v-if="getSnooze.open">
            <div class="additional-info-window">
              <div class="additional-info__header">
                <img
                  src="img/left-arrow-grey.svg"
                  @click="$store.dispatch('SnoozeToggle', { open: false })"
                  alt="Back"
                />
                Snooze message
              </div>
              <div class="additional-info__body">
                <ul v-if="getSnooze.record.length" class="snooze-message">
                  <li
                    v-for="(time, key) in getSnooze.record"
                    :key="key"
                    @click="setSnooze(time)"
                  >
                    {{ time }} Minutes <br />
                    <small>Snooze message in {{ time }} minutes</small>
                  </li>
                </ul>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { image } from "@/common/config";
import { loader } from "@/common/mixins/mix_loader";
import { mapGetters } from "vuex";
import { snooze, lsConfig } from "@/common/mixins/mix_localStorage";
import { staffCookies } from "@/common/mixins/mix_cookies";
import {
  request,
  download,
  waktu,
  alert,
  util,
} from "@/common/mixins/mix_helper";
import { actions } from "@/common/mixins/mix_actions";
import { agentMessage } from "@/common/mixins/mix_message";
import "toastify-js/src/toastify.css";

import PINCheck from "@/components/modules/PinCheck";
import Tags from "@/components/modules/Tags.vue";
import ClientProfile from "@/components/staff/chat_area/ClientProfile.vue";

export default {
  components: { ClientProfile, Tags, PINCheck },
  mixins: [
    actions,
    staffCookies,
    request,
    loader,
    download,
    waktu,
    snooze,
    lsConfig,
    agentMessage,
    alert,
    util,
  ],
  data() {
    return {
      cannedMessageOpen: false,
      addAgentOpen: false,
      arr: {
        staff: [], // menampung data staff after choice dept
        canned: [], // message canned for showing
        cannedMaster: [], // canned for storage filter
        departemen: [], // list all departemen
      },
      form: {
        departemen: "none",
        staff: "",
        cannedSearch: "",
      },
      image: image,
      handleTime: 1,
      handleTimeIncrement: null,
    };
  },
  methods: {
    toggleChatInfo: function () {
      this.$store.dispatch("toggleUserShow");
    },

    /**
     * --------------------------------------------------------------
     * request data canned per departement
     * --------------------------------------------------------------
     */
    xhrCanned: function () {
      let cookies = this.cookies.get();
      if (
        typeof cookies !== "undefined" &&
        typeof cookies.staff !== "undefined" &&
        typeof cookies.staff.deptid !== "undefined"
      ) {
        let deptid = cookies.staff.deptid;
        this.API.post(this.URL.canned.index, { deptid: deptid })
          .then(({ data }) => {
            this.arr.cannedMaster = data.data;
            this.arr.canned = this.arr.cannedMaster;
          })
          .catch((error) => {
            console.warn("canned err", error);
          });
      } else {
        this.$swal({
          title: "Error",
          html: "Did you change cookies? please call us.<br> [ code : SC-0001-!deptid ]",
          icon: "error",
          allowOutsideClick: false,
          showConfirmButton: false,
        });
      }
    },

    /**
     * --------------------------------------------------------------
     * request departement list
     * --------------------------------------------------------------
     */
    xhrDepartement: function () {
      this.API.get(this.URL.departemen.index)
        .then(({ data }) => {
          this.arr.departemen = data;
          this.form.departemen = "none"; //data[0].id;
          //this.triggerChangeDepartement();
        })
        .catch((err) => {
          console.warn("error", err);
        });
    },

    /**
     * --------------------------------------------------------------
     * for trigger open form add agent
     * --------------------------------------------------------------
     */
    btnOpenAddAgent: function () {
      this.addAgentOpen = true;
      this.form.departemen = "none"; // set default
      this.form.staff = "";
      this.arr.staff = [];
    },

    /**
     * --------------------------------------------------------------
     * submit add agent
     * --------------------------------------------------------------
     */
    onSubmitAddAgent: function () {
      if (this.form.departemen !== "none" && this.form.departemen !== "") {
        if (this.form.staff !== "") {
          this.$swal({
            title: "Warning",
            html: "Are you sure?",
            icon: "warning",
            showCancelButton: true,
          }).then(({ isConfirmed }) => {
            if (isConfirmed) {
              this.loading();
              let params = {
                deptid: parseInt(this.form.departemen),
                staffid: parseInt(this.form.staff),
                threadid: this.getDialogActive.id,
              };
              this.API.post(this.URL.agent.add, params)
                .then(({ data }) => {
                  this.$store.dispatch("appendNewAgent", data.data);
                  this.addAgentOpen = false;
                  this.$swal("Success", "Agent added successfully", "success");
                })
                .catch((error) => {
                  let message =
                    typeof error.response === "undefined"
                      ? error.message
                      : error.response.data.message;
                  this.$swal("Error", message, "error");
                })
                .finally(() => {
                  this.loading(false);
                });
            }
          });
        } else {
          this.$swal("Error", "Please select agent first.", "error");
        }
      } else {
        this.$swal("Error", "Please select department first.", "error");
      }
    },

    /**
     * --------------------------------------------------------------
     * export chat by threadid
     * --------------------------------------------------------------
     */
    xhrExport: function () {
      this.loading();
      this.API.post(this.URL.message.export, {
        threadid: this.getDialogActive.id,
      })
        .then(({ data }) => {
          let chat = data.data;
          let customer = chat.customer;
          let transcriptChat = `------------------ CUSTOMER ----------------------\n`;
          transcriptChat += `IP: ${customer.ip}\nNama: ${customer.nama}\nEmail: ${customer.email}\nReferrer: ${customer.referrer}`;
          transcriptChat += `\n\n------------------ TRANSCRIPT CHAT ------------------------------------\n\n`;
          chat.messages.forEach((item) => {
            transcriptChat += `[${item.timestamp}] ${item.nama} : ${item.message}\n`;
          });
          this.download(transcriptChat, `${chat.fileName}-${customer.email}`);
        })
        .catch((error) => {
          this.$swal("Error", error.response.data.message, "error");
        })
        .finally(() => {
          this.loading(false);
        });
    },

    /**
     * --------------------------------------------------------------
     * trigger chaining request staff
     * --------------------------------------------------------------
     */
    triggerChangeDepartement() {
      let deptid = this.form.departemen;
      if (deptid !== "none") {
        this.loading();
        if (deptid) {
          let roti = this.cookies.get();
          this.API.post(this.URL.departemen.staff, {
            deptid: deptid,
            current_staff: roti.staff.id,
          })
            .then(({ data }) => {
              this.arr.staff = data.data;
              this.form.staff = this.storage.randomAgentId; //data.data[0].id;
            })
            .catch((error) => {
              console.warn("staff err", error);
            })
            .finally(() => {
              this.loading(false);
            });
        }
      } else {
        this.$swal("Error", "Please select department first.", "error");
      }
    },

    /**
     * --------------------------------------------------------------
     * send canned message to parent
     * --------------------------------------------------------------
     */
    sendCanned: function (id) {
      this.$store.dispatch("textareaFocus", { focus: true, clear: false });
      this.$emit("sendCanned", {
        type: "sendCanned",
        data: this.arr.canned.find((msg) => msg.id === id),
      });
    },

    /**
     * --------------------------------------------------------------
     * click select message in result search
     * --------------------------------------------------------------
     */
    selectResultSearchMessage: function (message) {
      this.$store.dispatch("SearchMessage", { selected: message });
    },

    /**
     * --------------------------------------------------------------
     * handle snooze if agent klik list
     * --------------------------------------------------------------
     */
    setSnooze: function (time) {
      let timeShow = parseInt(time) * 60 + this.mixEpoch();
      let activeDialog = this.getDialogActive;
      let snooze = {
        threadId: activeDialog.id,
        timeShow: timeShow,
        createdAt: this.mixEpoch(),
        timeDelay: time,
        customerId: activeDialog.custid,
        customerName: activeDialog.name,
      };
      this.mixAddSnooze(snooze); // append localstorage
      this.$store.dispatch("SnoozePush", snooze); // send vuex

      // jika snooze sudah habis tapi staff masih snooze lagi
      this.mixSnoozeRemoveAlert(activeDialog.id, false);
      this.$store.dispatch("SnoozeRemoveAlert", {
        threadid: activeDialog.id,
        removeSnooze: false,
      });

      // alert to client
      let agent = this.cookies.get();
      let message = this.mixGenerateMessage({
        message: `This message has been snooze ${time} minutes by ${agent.staff.name}.`,
        actor: "system",
        tipe: "snooze",
      });
      this.$store.dispatch("staffSendMessage", { message: message });

      this.$swal(
        "Success",
        `You will be reminded in ${time} minutes`,
        "success"
      );
      this.$store.dispatch("SnoozeToggle", { open: false });
    },

    /**
     * --------------------------------------------------------------
     * for searching canned message
     * --------------------------------------------------------------
     */
    cannedSearch: function () {
      let cannedMaster = this.arr.cannedMaster;
      let word = this.form.cannedSearch.toLowerCase();
      this.arr.canned = cannedMaster.filter(
        ({ title, message }) =>
          title.toLowerCase().includes(word) ||
          message.toLowerCase().includes(word)
      );
    },

    // undoCloseChat(threadid) {
    //   this.loading();
    //   this.API.post(this.URL.thread.endUndo, {
    //     id: threadid,
    //   })
    //     .then(() => {
    //       this.mixToast("Undo thread close success!", {
    //         style: {
    //           background: "var(--success)",
    //         },
    //       });
    //     })
    //     .catch(() => {
    //       this.mixToast("Undo thread close failed!", {
    //         style: {
    //           background: "var(--danger)",
    //         },
    //       });
    //     })
    //     .finally(() => {
    //       this.loading(false);
    //     });
    // },

    /**
     * --------------------------------------------------
     * trigger time counter after away from browser (not tab)
     * --------------------------------------------------
     */
    visibilityChange() {
      if (!document.hidden) {
        const agent = this.getListAgent.find((e) => e.currentAgent);
        this.handleTime = this.mixEpoch() - agent.startTime;
      }
    },

    handleCronTab() {
      this.handleTime += 1;
    },
  },
  computed: {
    dialogNotEmpty: function () {
      return Object.keys(this.getDialogActive).length !== 0;
    },
    ...mapGetters([
      "getUserInfoOpen",
      "getDialogActive",
      "getListAgent",
      "getSearch",
      "getSnooze",
      "getAllVuexAgent",
    ]),
    storage: function () {
      return this.mixConfigGetter();
    },

    /**
     * --------------------------------------------------
     * get time handle thread from restapi via vuex
     * --------------------------------------------------
     */
    getHandleTimeAgent() {
      let time;
      let findCurrentAgent = this.getListAgent;
      for (let i in findCurrentAgent) {
        if (findCurrentAgent[i]["currentAgent"]) {
          time = findCurrentAgent[i]["handleTime"];
        }
      }
      return time;
    },

    /**
     * --------------------------------------------------
     * calculate handle time
     * --------------------------------------------------
     */
    _handleTimer() {
      return this.mixCalculateTime(this.handleTime);
    },
  },
  watch: {
    /**
     * --------------------------------------------------
     * remove interval before
     * and create agent interval for current thread
     * --------------------------------------------------
     */
    getHandleTimeAgent(baru) {
      this.handleTime = baru;
    },
  },
  created() {
    document.addEventListener("visibilitychange", this.visibilityChange, false);
  },
  mounted() {
    this.xhrCanned();
    this.xhrDepartement();
    this.$crontab.addJob({
      name: "timerchat",
      interval: {
        minutes: "/1",
      },
      job: this.handleCronTab,
    });
  },
};
</script>

<style scoped>
@import "../../assets/css/userinfo.css";
.bg-red {
  background: red;
}
.agent-notif {
  padding: 0 13px;
  text-align: center;
  margin-top: 4%;
}
ul.canned-ul li:hover,
ul.canned-ul li:nth-child(even):hover {
  background-color: #cce5ff;
}
ul.canned-ul li:nth-child(even) {
  background: #f6f6f6;
}
a:hover {
  text-decoration: none;
}
</style>
